import { ref, watch, computed } from '@vue/composition-api'
import { title } from '@core/utils/filter'
import Vue from 'vue'
// Notification

import axios from '@axios'
import store from '@/store'

export default function requestAuditing() {
  const reftransferListTable = ref(null)
  const mainServiceList = ref('')
  const mainServiceOption = ref([])
  const informs= ref([])
  // Table Handlers
  const tableColumns = [
    { key: 'id', label: ' الرقم', formatter: title },

    { key: 'created_by', label: 'الموظف', formatter: title },
     {
      isActive: false,
      key: 'title',
     label: ' العنوان ',
      formatter: title,

    },
     {
      isActive: false,
      key: 'body',
     label: ' النص ',
      formatter: title,

    },
    {
      isActive: false,
      key: 'start_date',
     label: 'تاريخ البداية ',
      formatter: title,

    },
   



    { key: 'actions', label: 'الخيارات' },
  ]
  const fetchListInform = (ctx, callback) => {
    const userData = JSON.parse(localStorage.getItem('userData'))
    //console.log('userData', userData.roles[0].id)

    // }else{
    store
      .dispatch('app-dashboard/fetchListInform', {

        page: currentPage.value,
        page_size:3,

      })
      .then(response => {
        informs.value=response.data.data
        // const { data, total } = response.data\
        //console.log('fetchListInform', response.data)
        // callback(data)
        totalUsers.value = response.data.meta.total
      })
      .catch(() => {
        Vue.swal({
          title: 'حدثت مشكلة في استرجاع البيانات',
          icon: 'error',

          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    // }
  }
  const perPage = ref(3)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const TransferType = ref('outgoing')
  const dataMeta = computed(() => {
    const localItemsCount = reftransferListTable.value
      ? reftransferListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    reftransferListTable.value.refresh()
  }
  watch(
    [
      TransferType,
      currentPage,
      perPage,
      searchQuery,

    ],
    () => {
      fetchListInform()
    },
  )

  const responseByEmployee = (id, statuts) => {
    // }else{
    store
      .dispatch('app-dashboard/responseByEmployee', {

        assigner_approve: statuts,
        id,
       

      })
      .then(response => {
        refetchData()
        Vue.swal({
          title: ' تم  بنجاح',

          icon: 'success',
          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
      .catch(() => {
        Vue.swal({
          title: `${response.data.message}`,
          icon: 'error',

          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    // }
  }
 
  fetchListInform()
  return {
    informs,
    fetchListInform,
    mainServiceOption,
    responseByEmployee,
    mainServiceList,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,

    reftransferListTable,
    TransferType,

    refetchData,

  }
}
